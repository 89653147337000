import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ToastMessageService } from '@mmu/app/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'mmu-password-recovery-successful',
  templateUrl: './password-recovery-successful.component.html',
  styleUrls: ['./password-recovery-successful.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRecoverySuccessfulComponent implements OnInit {
  private toastMessageService = inject(ToastMessageService);
  private router = inject(Router);

  ngOnInit(): void {
    this.toastMessageService.success(
      'Das Passwort wurde erfolgreich zurückgesetzt.',
      'Passwort zurückgesetzt'
    );

    setTimeout(() => this.router.navigateByUrl('/login'), 2000);
  }
}
