<div class="grid" style="margin: 10px">
  <ng-container *ngIf="manuals.length > 0">
    <div class="col-12">
      <span>Eine Anleitung zur Nutzung des MenüManager finden Sie hier:</span>
    </div>
    <div *ngFor="let manual of manuals" class="col-12">
      <div class="flex flex-row flex-gap align-items-center">
        <i class="fa-solid fa-file-pdf fa-lg color-mm-blue"></i>
        <a (click)="downloadPdf(manual.number)" class="cursor-pointer">{{
          manual.name
        }}</a>
      </div>
    </div>
    <div class="col-12">
      <span
        >Bei Fragen stehen wir Ihnen darüber hinaus werktags zwischen 09:00 und
        17:00 Uhr zur Verfügung:</span
      >
    </div>
    <div class="col-12">
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://sanalogic.atlassian.net/servicedesk/customer/portal/7"
        >MenüManager Support Ticketsystem</a
      >
    </div>
    <div class="col-12">
      <span>Telefonnummer: </span
      ><a href="tel:08001733733-900"> 0800 1733733-900</a>
    </div>
    <div class="col-12">
      <a href="mailto:support@menumanager.transgourmet.de"
        >support&#64;menumanager.transgourmet.de</a
      >
    </div>
    <div class="col-12">
      <span>Ihr Transgourmet-Team</span>
    </div>
  </ng-container>
</div>
