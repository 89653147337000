import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mmu-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  @Input() canCopy$ = new BehaviorSubject<boolean>(true);
  @Input() canPaste$ = new BehaviorSubject<boolean>(false);
  @Input() canAdd$ = new BehaviorSubject<boolean>(false);
  @Input() canDelete$ = new BehaviorSubject<boolean>(false);
  @Input() isDietReadonly!: boolean;
  @Input() styleClass = '';
  @Input() appendTo = '';

  @Output() copySelect = new EventEmitter();
  @Output() pasteSelect = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() delete = new EventEmitter();

  @ViewChild('menu', { static: false })
  menu!: Menu;
  private cachedMenuItems!: Array<MenuItem> | null;
  get menuItems(): Array<MenuItem> | null {
    if (this.cachedMenuItems !== null) {
      return this.cachedMenuItems;
    }

    const result = Array<MenuItem>();
    if (this._canAddRecipe) {
      result.push(this.addRecipeItem);
    }
    if (this._canDeleteRecipe) {
      result.push(this.deleteRecipeItem);
    }
    if (this._canCopy) {
      result.push(this.copyMenuItem);
    }
    if (this._canPaste) {
      result.push(this.pasteMenuItem);
    }

    this.cachedMenuItems = result;
    return result;
  }
  private _canAddRecipe!: boolean;
  private _canCopy!: boolean;
  private _canPaste!: boolean;
  private _canDeleteRecipe!: boolean;

  constructor(private readonly _cdRef: ChangeDetectorRef) {}

  private copyMenuItem = {
    label: 'Rezepte kopieren',
    icon: 'fa-solid fa-copy',
    command: () => this.onCopy(),
  } as MenuItem;

  private pasteMenuItem: MenuItem = {
    label: 'Rezepte einfügen',
    icon: 'fa-solid fa-copy',
    command: () => this.onPaste(),
  };

  private addRecipeItem = {
    label: 'Rezepte hinzufügen',
    icon: 'fa-solid fa-clipboard',
    command: () => this.onAddRecipe(),
  };

  private deleteRecipeItem = {
    label: 'Rezepte entfernen',
    icon: 'fa-solid fa-trash-can',
    command: () => this.onDeleteRecipe(),
  };

  ngOnInit(): void {
    this.canCopy$.subscribe((cc: boolean) => {
      this._canCopy = cc;
      this.cachedMenuItems = null;
      this._cdRef.detectChanges();
    });

    this.canPaste$.subscribe((cp: boolean) => {
      this._canPaste = cp;
      this.cachedMenuItems = null;
      //update if the 3-dots are still grey or not
      this._cdRef.detectChanges();
    });

    this.canAdd$.subscribe((ca: boolean) => {
      this._canAddRecipe = ca;
      this.cachedMenuItems = null;
      this._cdRef.detectChanges();
    });

    this.canDelete$.subscribe((cd: boolean) => {
      this._canDeleteRecipe = cd;
      this.cachedMenuItems = null;
      this._cdRef.detectChanges();
    });
  }

  onCopy(): void {
    this.copySelect.emit();
  }

  onPaste(): void {
    this.pasteSelect.emit();
  }

  onAddRecipe(): void {
    this.add.emit();
  }

  onDeleteRecipe(): void {
    this.delete.emit();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggleMenu = (originalEvent: Event) => {
    this.menu.toggle(originalEvent);
  };
}
