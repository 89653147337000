<div>
  <p>
    {{ dialogParameters.message ?? "" }}
  </p>
</div>
<div class="dialog-content-footer">
  <button
    type="button"
    pButton
    icon="fa-regular fa-xmark"
    label="{{ dialogParameters.labels?.negate }}"
    (click)="reject()"
  ></button>
  <button
    type="button"
    pButton
    icon="fa-regular fa-check"
    label="{{ dialogParameters.labels?.confirm }}"
    (click)="accept()"
  ></button>
</div>
