<div class="p-fluid">
  <div class="field grid">
    <div [ngClass]="searchTerm.length > 0 ? 'col-10' : 'col-12'">
      <span class="p-input-icon-left">
        <i class="fa-regular fa-magnifying-glass"></i>
        <input
          type="text"
          pInputText
          [(ngModel)]="searchTerm"
          (ngModelChange)="onSearchTermChange($event)"
          [placeholder]="placeholder"
        />
      </span>
    </div>
    <div class="col-2">
      <mmu-clearbutton
        class="flex flex-row align-self-center"
        *ngIf="searchTerm.length > 0"
        [showClearButton]="searchTerm.length > 0"
        (cleanValue)="onCleanSearchTermClick()"
      ></mmu-clearbutton>
    </div>
  </div>
</div>
