import { NotificationDto } from './../models/notification-dto';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notification.actions';

export interface NotificationState {
  notificationList: NotificationDto[];
  notificationsPendingState: boolean;
}

export const initialState: NotificationState = {
  notificationList: [],
  notificationsPendingState: false,
};

export const notificationFeature = createFeature({
  name: 'notification',
  reducer: createReducer(
    initialState,
    on(NotificationActions.getNotificationList, (state: NotificationState) => ({
      ...state,
      notificationsPendingState: true,
    })),
    on(
      NotificationActions.getNotificationListSuccess,
      (state: NotificationState, { payload }) => ({
        ...state,
        notificationList: payload.notificationList,
        notificationsPendingState: false,
      })
    ),
    on(
      NotificationActions.setNotificationRead,
      (state: NotificationState, { payload }) => {
        return {
          ...state,
          notificationList: state.notificationList.map((item) => {
            if (item.number === payload.number) {
              const isUnread = false;
              item = {
                ...item,
                isUnread,
              };
            }
            return item;
          }),
        };
      }
    ),
    on(
      NotificationActions.setNotificationReadSuccess,
      (state: NotificationState, { payload }) => ({
        ...state,
        notificationList: payload.notificationList,
      })
    )
  ),
});

export const {
  selectNotificationState,
  selectNotificationList,
  selectNotificationsPendingState,
} = notificationFeature;
