import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ROOT_REDUCERS, metaReducers } from './app.reducers';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './core/data-access/effects/core.effects';
import { OpenTelemetryModule } from 'ui-core-otel';
import {
  LoginConfiguration,
  LoginStorageType,
  provideLogin,
  interceptAuthentication,
} from '@sanalogic/ui-core/login';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { AppToastsComponent } from './core/features/toast/app-toasts.component';
import { MmHttpErrorInterceptor } from './core/services';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LoaderInterceptor } from '@mmu/app/core/interceptors/loader.interceptor';
import { AntiforgeryTokenInterceptor } from '@mmu/app/core/interceptors/antiforgery-token.interceptor';
import {
  StorageConfiguration,
  provideStorage,
} from '@sanalogic/ui-core/storage';
import { provideTranslation } from '@sanalogic/ui-core/translate';

registerLocaleData(localeDe);

const loginConfiguration: LoginConfiguration = {
  multiTabTokenSharing: false,
  tokenRefreshMode: 'BeforeHttpRequest',
  storage: LoginStorageType.sessionStorage,
  deviceIdPrefix: 'MM',
  sharedTokenIdentifier: 'fragment',
};

const storageConfig: StorageConfiguration = {
  defaultStorage: 'Session',
  prefix: 'MM',
};

@NgModule({
  declarations: [AppComponent, AppToastsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastModule,

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    EffectsModule.forRoot([CoreEffects]),
    CoreModule,
    HttpClientModule,
    OpenTelemetryModule.forRoot(environment.openTelemetryConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.devPwa,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
      scope: './offline/',
    }),
  ],
  providers: [
    MessageService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AntiforgeryTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MmHttpErrorInterceptor,
      multi: true,
    },
    provideLogin(loginConfiguration),
    provideStorage(storageConfig),
    provideHttpClient(
      withInterceptors([interceptAuthentication()]),
      withInterceptorsFromDi()
    ),
    provideTranslation({
      httpLoaderOptions: {
        baseUrl: 'assets/translations/',
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
