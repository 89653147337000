import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UserActivityDto } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  constructor(private _httpClient: HttpClient) {}

  addUserActivity(activity: UserActivityDto): Observable<boolean> {
    const url = `/api/MenuManagerService/UserActivities`;

    return this._httpClient
      .post(url, activity)
      .pipe(map((response) => (response ? true : false)));
  }
}
