<div class="login-page">
  <div class="login">
    <div class="concern">
      <img src="assets/logos/tg_quer_2D_cmyk.svg" alt="" />
    </div>
    <div class="info">
      <div class="flex flex-column">
        <p>Hallo lieber Kunde,</p>
        <p>
          Ihr individueller MenüManager steht bereit. Jetzt mit Knopfdruck die
          Küche leistungsfähiger, übersichtlicher und effizienter machen!
        </p>
        <p>
          Bei Fragen oder Problemen wenden Sie sich bitte an den
          First-Level-Support:
        </p>
        <div class="contact flex flex-column flex-column-gap">
          <div class="flex flex-row flex-row-gap align-items-center">
            <i class="fa-regular fa-circle-question"></i>
            <a
              class="p-button-link overflow-clip"
              href="https://sanalogic.atlassian.net/servicedesk/customer/portal/7"
              rel="noopener noreferrer"
              target="_blank"
              >MenüManager Support Ticketsystem</a
            >
          </div>
          <div class="flex flex-row flex-row-gap align-items-center">
            <i class="fa-regular fa-phone"></i>
            <a class="p-button-link overflow-clip" href="tel:08001733733900"
              >0800 1733733-900</a
            >
          </div>
          <div class="flex flex-row flex-row-gap align-items-center">
            <i class="fa-regular fa-envelope"></i>
            <a
              class="p-button-link overflow-clip"
              href="mailto:support@menumanager.transgourmet.de?subject=MenüManager"
              >support&#64;menumanager.transgourmet.de</a
            >
          </div>
        </div>
        <p>Ihr Transgourmet-Team</p>
      </div>
    </div>
    <div class="title flex flex-column flex-column-gap">
      <div class="product flex flex-row flex-row-gap align-items-center">
        <img
          src="assets/logos/logo_mm.png"
          class="mm-logo"
          alt="MenuManager Logo"
        />
        <ng-container *ngIf="mmEnvironment">
          <p-badge [value]="mmEnvironment.name"></p-badge>
        </ng-container>
      </div>
    </div>
    <form
      [formGroup]="loginFormGroup"
      class="form flex flex-column justify-content-between"
    >
      <div class="flex flex-column flex-column-gap p-fluid">
        <span class="p-float-label">
          <input
            id="fi-username"
            type="text"
            pInputText
            formControlName="username"
            autocomplete="off"
            [readonly]="pending"
            autofocus
          />
          <label for="fi-username">Benutzername</label>
        </span>
        <span class="p-float-label mt-4">
          <input
            id="fi-password"
            type="password"
            pInputText
            formControlName="password"
            autocomplete="off"
            [readonly]="pending"
          />
          <label for="fi-password">Passwort</label>
        </span>
        <a class="forgot-password-link pt-1" routerLink="/password-recovery">
          Passwort vergessen
        </a>
        <button
          pButton
          type="submit"
          label="Anmelden"
          [disabled]="pending || !loginFormGroup.valid"
          (click)="onSubmit()"
          class="mt-4"
        ></button>
        <div *ngIf="errorMessage" class="login-error">
          {{ errorMessage }}
        </div>
        <a routerLink="/onboarding"> Sie sind noch kein MenüManager Kunde? </a>
      </div>
      <div class="impress flex flex-row justify-content-between">
        <p>
          <a
            href="https://www.transgourmet.de/impressum"
            target="_blank"
            rel="noopener noreferrer"
            >Impressum</a
          >
        </p>
        <p>
          <a
            href="https://www.transgourmet.de/datenschutzerklaerung"
            target="_blank"
            rel="noopener noreferrer"
            >Datenschutz</a
          >
        </p>
      </div>
    </form>
  </div>
</div>
