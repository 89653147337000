import { createFeature, createReducer, on } from '@ngrx/store';
import * as SettingsActions from '../actions/settings.actions';

export interface SettingsState {
  timeSpanInSeconds: number;
  menuplanAllowedWeeks: number;
}

export const initialState: SettingsState = {
  timeSpanInSeconds: 0,
  menuplanAllowedWeeks: 0,
};

export const settingsFeature = createFeature({
  name: 'settings',
  reducer: createReducer(
    initialState,
    on(SettingsActions.loadSettings, (state: SettingsState) => ({ ...state })),
    on(
      SettingsActions.loadSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        timeSpanInSeconds: payload.settings.timeSpanInSeconds ?? 0,
        menuplanAllowedWeeks: payload.settings.menuplanAllowedWeeks,
      })
    ),
    on(SettingsActions.loadSettingsFail, (state: SettingsState) => ({
      ...state,
    }))
  ),
});

export const {
  selectSettingsState,
  selectTimeSpanInSeconds,
  selectMenuplanAllowedWeeks,
} = settingsFeature;
