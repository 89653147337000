import { inject, Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  AuthenticationV2Service,
  SetPasswordStatusType,
} from '@sanalogic/ui-core/login';
import { PasswordChangeContainerComponent } from '@mmu/app/core/features/change-password/password-change-container.component';
import { filter, finalize, take } from 'rxjs/operators';
import { Observable, switchMap, tap } from 'rxjs';
import { LoginState } from '@mmu/app/core/data-access/reducers/login.reducer';
import { Store } from '@ngrx/store';
import * as LoginAction from '@mmu/app/core/data-access/actions/login.actions';

@Injectable({ providedIn: 'root' })
export class PasswordChangeService {
  private readonly dialogService = inject(DialogService);
  private readonly authenticationService = inject(AuthenticationV2Service);
  private readonly loginStore = inject(Store<LoginState>);

  private dialogRef!: DynamicDialogRef;

  openPasswordChangeDialog(oldPassword?: string) {
    this.dialogRef = this.dialogService.open(PasswordChangeContainerComponent, {
      header: 'Passwort ändern',
      width: '480px',
      data: {
        password: oldPassword,
      },
    });
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
    redirectToKitchen: boolean
  ): void {
    this.authenticationService.changePassword(currentPassword, newPassword);

    this.authenticationService.passwordChangeStatus$
      .pipe(
        filter((status) => status === SetPasswordStatusType.Successful),
        take(1),
        switchMap(() =>
          this.loginAfterPasswordChange(newPassword, redirectToKitchen)
        ),
        finalize(() => this.closePasswordChangeDialog())
      )
      .subscribe();
  }

  closePasswordChangeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private loginAfterPasswordChange(
    newPassword: string,
    redirectToKitchen: boolean
  ): Observable<string> {
    return this.authenticationService.isLoggedIn$.pipe(
      take(1),
      filter((loggedIn) => !loggedIn), // only proceed if not logged in
      switchMap(() =>
        this.authenticationService.username$.pipe(
          take(1),
          tap((username) => {
            this.loginStore.dispatch(
              LoginAction.login({
                payload: {
                  username: username,
                  password: newPassword,
                  redirectToKitchen: redirectToKitchen,
                },
              })
            );
          })
        )
      )
    );
  }
}
