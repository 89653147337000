import { createFeature, createReducer, on } from '@ngrx/store';
import { EnvironmentDto } from '../../models';
import * as EnvironmentActions from '../actions/environment.action';

export interface EnvironmentState {
  environment: EnvironmentDto | undefined | null;
}

export const initialState: EnvironmentState = {
  environment: null,
};

export const environmentFeature = createFeature({
  name: 'environment',
  reducer: createReducer(
    initialState,
    on(EnvironmentActions.loadEnvironment, (state: EnvironmentState) => ({
      ...state,
    })),
    on(
      EnvironmentActions.loadEnvironmentSuccess,
      (state: EnvironmentState, { payload }) => ({
        ...state,
        environment: payload.environment,
      })
    ),
    on(EnvironmentActions.loadEnvironmentFail, (state: EnvironmentState) => ({
      ...state,
    }))
  ),
});

export const { selectEnvironmentState, selectEnvironment } = environmentFeature;
