import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keywordLink',
})
export class KeywordLinkPipe implements PipeTransform {
  transform(value: Keyword[]): string {
    if (value?.length > 0) {
      const formattedKeywords = value
        .map((keyword) => keyword['name'])
        .join(', ');
      return formattedKeywords;
    }
    return 'Keine Schlagwörter vorhanden.';
  }
}

export interface Keyword {
  keywordMainGroupNumber: number;
  number: number;
  name: string;
}
