import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentDto } from '../../models';

export const loadEnvironment = createAction(
  '[Environment] Load environment'
  // props<{ payload: { }}>()
);

export const loadEnvironmentSuccess = createAction(
  '[Environment] Load environment success',
  props<{ payload: { environment: EnvironmentDto | undefined } }>()
);

export const loadEnvironmentFail = createAction(
  '[Environment] Load environment fail',
  props<{ payload: { error: HttpErrorResponse } }>()
);
