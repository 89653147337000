import { Pipe, PipeTransform } from '@angular/core';
import { PmmInventoryLogStateEnum } from '@mmu/app/inventory/domain';

@Pipe({
  name: 'inventoryLogState',
})
export class InventoryLogStatePipe implements PipeTransform {
  transform(value: PmmInventoryLogStateEnum | undefined): string {
    switch (value) {
      case PmmInventoryLogStateEnum.Opened:
        return 'Inventur geöffnet';
      case PmmInventoryLogStateEnum.Closed:
        return 'Inventur abgeschlossen';
    }
    return '';
  }
}
