import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'mmu-dialog-inputfield',
  templateUrl: './dialog-inputfield.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogInputfieldComponent implements OnInit {
  @Input() valueInputForm = '';
  @Input() maxLength?: number;
  @Input() rowsInputField = 10;
  @Input() colsInputField = 100;
  @Input() labelOfConfirmButton = 'Ok';
  @Output() valueInputFormEmitter = new EventEmitter<string>();
  dialogInputfield: UntypedFormGroup | null = null;
  constructor(private _formBuilder: UntypedFormBuilder) {}

  get valueInput(): AbstractControl | null {
    if (this.dialogInputfield) {
      return this.dialogInputfield.controls['valueInputForm'];
    }
    return null;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.dialogInputfield = this._formBuilder.group({
      valueInputForm: [
        this.valueInputForm,
        this.maxLength ? [Validators.maxLength(this.maxLength)] : [],
      ],
    });
  }

  setOriginFormValue(): void {
    if (this.dialogInputfield) {
      this.valueInputForm = this.dialogInputfield.get('valueInputForm')?.value;
      this.valueInputFormEmitter.emit(this.valueInputForm);
    }
  }
}
