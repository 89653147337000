import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DietKitchenMappingBaseDto } from '../models/diet-kitchen-mappingb.dto';
import { PmmKitchenBaseSettingsDto } from '../models/pmm-kitchen-base-settings.dto';

@Injectable({ providedIn: 'root' })
export class KitchenSettingsService {
  constructor(private readonly _httpClient: HttpClient) {}

  getKitchenSetting<T>(key: string): Observable<T> {
    const url = `/api/MenuManagerService/Settings/Kitchen/${key}`;
    return this._httpClient.get<T>(url);
  }
  addOrUpdateKitchenSetting<T>(value: T, key: string): Observable<T> {
    const url = `/api/MenuManagerService/Settings/Kitchen/${key}`;
    return this._httpClient.post<T>(url, value);
  }

  getKitchenSettings(): Observable<PmmKitchenBaseSettingsDto> {
    const url = '/api/MenuManagerService/Settings/Kitchen/BaseSettings';
    return this._httpClient.get<PmmKitchenBaseSettingsDto>(url);
  }
  updateOrCreateKitchenSetting(
    value: PmmKitchenBaseSettingsDto
  ): Observable<PmmKitchenBaseSettingsDto> {
    const url = '/api/MenuManagerService/Settings/Kitchen/BaseSettings';
    return this._httpClient.post<PmmKitchenBaseSettingsDto>(url, value);
  }

  updateDietDisplayOrder(
    updateValue: DietKitchenMappingBaseDto[]
  ): Observable<DietKitchenMappingBaseDto[]> {
    const url = '/api/MenuManagerService/Settings/Kitchen/DietOrder';
    return this._httpClient.put<DietKitchenMappingBaseDto[]>(url, updateValue);
  }
}
