<div class="topbar clearfix">
  <div class="topbar-left">
    <a routerLink="/">
      <ng-container [ngSwitch]="displayTypeService.displayType$ | async">
        <img
          *ngSwitchCase="displayTypes.Mobile"
          src="assets/logos/tg_quer_2D_cmyk_logo.svg"
          class="topbar-logo"
          routerLink="/"
        />
        <img
          *ngSwitchDefault
          src="assets/logos/tg_quer_2D_cmyk.svg"
          class="topbar-logo"
          routerLink="/"
        />
      </ng-container>
    </a>
  </div>

  <div
    class="topbar-right"
    style="display: flex; flex-direction: row; align-items: center"
  >
    <a
      id="menu-button"
      href="#"
      (click)="mainComponent.onMenuButtonClick($event)"
      [ngClass]="{ 'menu-button-rotate': mainComponent.rotateMenuButton }"
    >
      <i class="fa-solid fa-angle-left"></i>
    </a>
    <div class="flex flex-row flex-row-gap align-items-center">
      <span class="mm-Name">MenüManager</span>
      <ng-container *ngIf="mmEnvironment">
        <p-badge [value]="mmEnvironment.name"></p-badge>
      </ng-container>
    </div>

    <div style="margin-left: auto" *ngIf="!offline">
      <ng-container
        *uicHasPrivilegeV2="
          privileges.DisplaySwitchCustomer;
          then switchKitchen;
          else normal
        "
      >
      </ng-container>
      <ng-template #switchKitchen>
        <div class="flex cursor-pointer hover">
          <div
            class="mr-2 flex flex-row flex-row-gap align-items-center"
            [routerLink]="['/customers']"
          >
            <ng-container *ngIf="kitchenName$ | async as Kitchenname">
              <div
                style="display: flex; margin-right: 10px"
                pTooltip="Kunde wechseln"
                tooltipPosition="bottom"
              >
                <i class="fa-solid fa-random switchKitchenIcon"></i>
                <span style="align-items: center">{{ Kitchenname }}</span>
              </div>
            </ng-container>
          </div>
          <div
            class="mr-1"
            pTooltip="Benachrichtigungen"
            tooltipPosition="left"
          >
            <mmu-notification-center-container>
            </mmu-notification-center-container>
          </div>
        </div>
      </ng-template>
      <ng-template #normal>
        <div class="mr-2">
          <ng-container *ngIf="kitchenName$ | async as kitchenname">
            <div>{{ kitchenname }}</div>
          </ng-container>
        </div>
        <div class="mr-1 flex flex-row flex-row-gap align-items-center">
          <mmu-notification-center-container>
          </mmu-notification-center-container>
        </div>
      </ng-template>
    </div>
    <ul
      class="topbar-items fadeInDown"
      [ngClass]="{ 'topbar-items-visible': mainComponent.topbarMenuActive }"
    ></ul>
  </div>

  <div *ngIf="loaderService.isLoading$ | async">
    <p-progressBar
      [style]="{ height: '6px' }"
      mode="indeterminate"
    ></p-progressBar>
  </div>
</div>
