import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  ContentChild,
} from '@angular/core';

@Component({
  selector: 'mmu-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() iconClass = '';
  @Input() showBackButton = true;
  @Input() disableBackButton = false;
  @Input() content = '';
  @Input() isSticky = true;
  @Input() targetUrl = '';

  @ContentChild('titleTemplate') titleTemplate: TemplateRef<string> | null =
    null;
}
