import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mmuKeyboardNavPrevention]',
})
export class KeyboardNavigationPreventionDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    const charCode = event.which ?? event.keyCode;
    if (charCode === 37 || charCode === 39) {
      event.stopPropagation();
    }
  }
}
