import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mmu-actionbar',
  template: `
    <div class="actionbar">
      <div class="actionbar-header">
        <span>{{ header }}</span>
      </div>
      <div class="actionbar-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./actionbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionbarComponent {
  @Input() header = '';
}
