import { createFeature, createReducer, on } from '@ngrx/store';
import * as LoginActions from '../actions/login.actions';

export interface LoginState {
  status: number | null;
  statusOffline: number | null;
  username: string;
  nextAllowedLoginTimeUtc: Date | undefined;
  currentKitchenNumber: number | null;
  xsrf: boolean;
}

export const initialState: LoginState = {
  status: null,
  statusOffline: null,
  xsrf: false,
  username: '',
  nextAllowedLoginTimeUtc: undefined,
  currentKitchenNumber: null,
};

export const loginFeature = createFeature({
  name: 'login',
  reducer: createReducer(
    initialState,
    on(LoginActions.login, (state: LoginState, { payload }) => ({
      ...state,
      username: payload.username,
      status: null,
    })),
    on(LoginActions.loginSuccess, (state: LoginState, { payload }) => ({
      ...state,
      status: payload.status,
      xsrf: false,
    })),
    on(LoginActions.loginNotSuccess, (state: LoginState, { payload }) => ({
      ...state,
      status: payload.status,
      username: payload.username,
      nextAllowedLoginTimeUtc: payload.nextAllowedLoginTimeUtc,
    })),
    on(LoginActions.loginFailed, (state: LoginState) => ({ ...state })),
    on(LoginActions.logout, (state: LoginState) => ({
      ...state,
      status: null,
    })),
    on(LoginActions.logoutSuccess, (state: LoginState) => ({ ...state })),

    on(LoginActions.loginOfflineInventorySuccess, (state: LoginState) => ({
      ...state,
      statusOffline: state.status,
      xsrf: false,
    })),
    on(LoginActions.xsrfToken, (state: LoginState) => ({
      ...state,
      xsrf: true,
    }))
  ),
});

export const {
  selectLoginState,
  selectStatusOffline,
  selectStatus,
  selectUsername,
  selectXsrf,
} = loginFeature;
