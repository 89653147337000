import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ManualsDownloadContainerComponent } from '../manuals-donwnload/manuals-download-container.component';

@Component({
  selector: 'mmu-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(private readonly _dialogService: DialogService) {}

  openManualsDownloadDialog(): void {
    this._dialogService.open(ManualsDownloadContainerComponent, {
      header: 'Hilfe und Kontakt',
      width: '400px',
    });
  }
}
