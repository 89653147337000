import {
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Directive } from '@angular/core';

export const isZero: ValidatorFn = (
  control: AbstractControl
): ValidationErrors => {
  const controlValue = control.value;
  return controlValue === 0 ? { isZero: true } : { isZero: false };
};

@Directive({
  selector: '[mmuZero]',
})
export class ZeroDirective implements Validators {
  validate(): ValidatorFn {
    return isZero;
  }
}
