import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthorizationV2Service } from '@sanalogic/ui-core/login';
import { UserPrivilege } from '../../../core/models';
import {
  SupplierArticleForSearchWarehouseStatusDto,
  SupplierArticlePriceType,
} from '@mmu/app/article/domain';
import { Observable } from 'rxjs';

@Component({
  selector: 'mmu-mark',
  templateUrl: './mark.component.html',
  styleUrls: ['./mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkComponent implements OnInit {
  @Input() warehouseStatus!: SupplierArticleForSearchWarehouseStatusDto;
  @Input() isArticleLocked = false;
  @Input() isManualOrder!: boolean;
  @Input() priceType: SupplierArticlePriceType | null = null;
  @Input() showSubRecipe = false;
  @Input() showMenuplanIcon = false;
  @Input() showUserEditIcon = false;
  @Input() componentProblem = false;
  @Input() isUsedInOwnComponent = false;

  priceTypeShort = '';

  constructor(private authorizationService: AuthorizationV2Service) {}

  ngOnInit(): void {
    this.setPriceType();
  }

  getAllowLockIndicators(): Observable<boolean> {
    return this.authorizationService.hasPrivilege(
      UserPrivilege.DisplayArticleMark
    );
  }

  getWarehouseStatusTooltip(): string {
    const status = [
      this.warehouseStatus?.isCanceled ? 'Auslauf' : '',
      this.warehouseStatus?.isDeleted ? 'Gelöscht' : '',
      this.warehouseStatus?.isLocked || this.isArticleLocked ? 'Gesperrt' : '',
    ];
    return status.filter((v) => v.length > 0).join(', ');
  }

  setPriceType(): void {
    switch (this.priceType?.priceType) {
      case 1:
        this.priceTypeShort = 'SP';
        break;
      case 2:
        this.priceTypeShort = 'PP';
        break;
      case 3:
        this.priceTypeShort = 'KP';
        break;
      default:
        this.priceTypeShort = '';
    }
  }
}
