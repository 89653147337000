import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MenuItem, MenuplanCardItem, MenuplanCardItemAction } from './models';

@Component({
  selector: 'mmu-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCardComponent {
  @Input() title = '';
  @Input() ordersTotalCount = 0;
  @Input() menuItems = new Array<MenuItem>();
  @Input() entries = new Array<MenuplanCardItem>();
  @Input() disabled = false;

  @Output() titleClicked = new EventEmitter();
  @Output() totalOrdersClicked = new EventEmitter();
  @Output() menuItemSelected = new EventEmitter<MenuItem>();
  @Output() menuplanCardItemSelected = new EventEmitter<MenuplanCardItem>();
  @Output() menuplanCardItemSelectedAction =
    new EventEmitter<MenuplanCardItemAction>();

  onClickTitle(): void {
    this.titleClicked.emit();
  }

  onClickTotalOrders(): void {
    this.totalOrdersClicked.emit();
  }

  onClickMenuCardSelected(menuplanCard: MenuplanCardItem): void {
    if (!this.disabled) {
      this.menuplanCardItemSelected.emit(menuplanCard);
    }
  }

  onClickMenuCardItemAction(menuplanCardAction: MenuplanCardItemAction): void {
    if (!this.disabled) {
      this.menuplanCardItemSelectedAction.emit(menuplanCardAction);
    }
  }
}
