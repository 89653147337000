import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogParameters } from '../../../core/models/dialog-parameters';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'mmu-order-dialog-view',
  templateUrl: './order-dialog-view.component.html',
  styleUrls: ['./order-dialog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDialogViewComponent implements OnInit {
  private _redirectTimer = 5;
  private _redirectTimerSubject = new BehaviorSubject<number>(5);
  redirectTimerObservable$ = this._redirectTimerSubject.asObservable();

  dialogParameters!: DialogParameters;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.dialogParameters = this.config.data as DialogParameters;
    this.startRedirectCountdown(
      this._redirectTimer,
      this.dialogParameters.url ?? ''
    );
  }

  closeDialog(): void {
    this.ref.close();
  }

  startRedirectCountdown(seconds: number, url: string): void {
    this._redirectTimer = seconds;
    const redirectInterval = interval(1000).pipe(
      map(() => {
        this._redirectTimerSubject.next(--this._redirectTimer);
        if (this._redirectTimer <= 0) {
          redirectIntervalSubscription.unsubscribe();
          window.open(url);
        }
      })
    );
    const redirectIntervalSubscription = redirectInterval.subscribe();
  }
}
