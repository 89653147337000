import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SettingsService } from '../infrastructure/settings.service';
import * as actions from './settings.actions';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '../../../core/services';
import {
  NutritionalUserSettings,
  NutritionalUserSettingsDisplayFormatEnum,
  ReportingUserSettings,
} from '../models';
import { UserSettingsService } from '../infrastructure/user-settings.service';
import { KitchenSettingsService } from '../infrastructure/kitchen-settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from '@mmu/app/core/models';
import { AGBSettingsDto } from '../models/agb-settings.dto';

@Injectable()
export class SettingsEffects {
  constructor(
    private readonly _actions: Actions,
    private readonly _settingsService: SettingsService,
    private readonly _userSettingsService: UserSettingsService,
    private readonly _toastMessageService: ToastMessageService,
    private readonly _kitchenSettingsService: KitchenSettingsService
  ) {}

  getDietAllocations$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getDietAllocations),
      switchMap(() =>
        this._settingsService.getDietAllocations().pipe(
          map((response) =>
            actions.getDietAllocationsSuccess({
              payload: { dietAllocations: response },
            })
          ),
          catchError((error) =>
            of(
              actions.getDietAllocationsFailed({
                payload: { error },
              })
            )
          )
        )
      )
    )
  );

  addietAllocation$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.addDietAllocation),
      switchMap((action) =>
        this._settingsService
          .addDietAllocation(action.payload.dietAllocation)
          .pipe(
            map((response) => {
              this._toastMessageService.success(
                'Kostformzuordnung erfolgreich hinzugefügt.'
              );
              return actions.addDietAllocationSuccess({
                payload: { dietAllocation: response },
              });
            }),
            catchError((error) =>
              of(
                actions.addDietAllocationFailed({
                  payload: { error },
                })
              )
            )
          )
      )
    )
  );

  updateDietAllocationName$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.updateDietAllocationName),
      switchMap((action) =>
        this._settingsService
          .updateDietAllocationName(action.payload.dietAllocation)
          .pipe(
            map((response) => {
              this._toastMessageService.success('Kostformname aktualisiert.');
              return actions.updateDietAllocationNameSuccess({
                payload: { dietAllocation: response },
              });
            }),
            catchError((error) =>
              of(
                actions.updateDietAllocationNameFailed({
                  payload: { error },
                })
              )
            )
          )
      )
    )
  );

  removeDietAllocation$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.removeDietAllocation),
      switchMap((action) =>
        this._settingsService
          .removeDietAllocation(action.payload.dietAllocation)
          .pipe(
            map(() => {
              this._toastMessageService.success(
                'Kostformzuordnung deaktiviert.'
              );
              return actions.removeDietAllocationSuccess({
                payload: { dietAllocation: action.payload.dietAllocation },
              });
            }),
            catchError((error) => {
              if (error.statusCode === 400) {
                this._toastMessageService.error(
                  'Speichern fehlgeschlagen. Es muss mindestens eine Kostform aktiv bleiben.'
                );
              }
              return of(
                actions.removeDietAllocationFailed({
                  payload: { error },
                }),
                actions.getDietAllocations()
              );
            })
          )
      )
    )
  );

  removeDietAllocationName$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.removeDietAllocationName),
      switchMap((action) =>
        this._settingsService
          .removeDietAllocationName(action.payload.dietAllocation)
          .pipe(
            map(() => {
              this._toastMessageService.success(
                'Kostformbezeichnung gelöscht.'
              );
              return actions.removeDietAllocationNameSuccess({
                payload: { dietAllocation: action.payload.dietAllocation },
              });
            }),
            catchError((error) =>
              of(
                actions.removeDietAllocationNameFailed({
                  payload: { error },
                })
              )
            )
          )
      )
    )
  );

  getAGBSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getAGBSettings),
      switchMap(() =>
        this._kitchenSettingsService
          .getKitchenSetting<AGBSettingsDto>('AGBSettings')
          .pipe(
            map((response) =>
              actions.getAGBSettingsSuccess({
                payload: { AGBSettings: response },
              })
            ),
            catchError((error) => {
              const ERROR = JSON.stringify(error);
              return of(
                actions.getAGBSettingsFailed({
                  payload: { error: ERROR },
                })
              );
            })
          )
      )
    )
  );

  updateAGBSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.updateAGBSettings),
      switchMap((action) =>
        this._kitchenSettingsService
          .addOrUpdateKitchenSetting<AGBSettingsDto>(
            action.payload.AGBSettings,
            'AGBSettings'
          )
          .pipe(
            map(() => {
              this._toastMessageService.success(
                'Nutzungsbedingungen erfolgreich akzeptiert.'
              );
              return actions.updateAGBSettingsSuccess({
                payload: {
                  AGBSettings: action.payload.AGBSettings,
                },
              });
            }),
            catchError((error) => {
              const ERROR = JSON.stringify(error);
              return of(
                actions.updateAGBSettingsFailed({
                  payload: { error: ERROR },
                })
              );
            })
          )
      )
    )
  );

  getReportUserSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(
        actions.getReportUserSettings,
        actions.updateReportUserSettingsSuccess
      ),
      switchMap(() =>
        this._userSettingsService
          .getUserSettings<ReportingUserSettings>('ReportingUserSettings')
          .pipe(
            map((response) =>
              actions.getReportUserSettingsSuccess({
                payload: { reportUserSettings: response },
              })
            ),
            catchError((error) => {
              const ERROR = JSON.stringify(error);
              return of(
                actions.getReportUserSettingsFailed({
                  payload: { error: ERROR },
                })
              );
            })
          )
      )
    )
  );

  updateReportUserSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.updateReportUserSettings),
      switchMap((action) =>
        this._userSettingsService
          .updateOrCreateUserSettings<ReportingUserSettings>(
            'ReportingUserSettings',
            action.payload.reportUserSettings
          )
          .pipe(
            map(() => {
              this._toastMessageService.success(
                'Benachrichtigung erfolgreich aktualisiert.'
              );
              return actions.updateReportUserSettingsSuccess({
                payload: {
                  reportUserSettings: action.payload.reportUserSettings,
                },
              });
            }),
            catchError((error) => {
              const ERROR = JSON.stringify(error);
              return of(
                actions.updateReportUserSettingsFailed({
                  payload: { error: ERROR },
                })
              );
            })
          )
      )
    )
  );

  addMissedReportUserSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.getReportUserSettingsFailed),
      switchMap((action) => {
        const ERROR = JSON.parse(action.payload.error);
        if (ERROR?.statusText === 'Not Found') {
          const SETTINGS: ReportingUserSettings = {
            autoMenuplanReports: true,
            autoOrderabiltityCheckReports: true,
          };

          return of(
            actions.updateReportUserSettings({
              payload: { reportUserSettings: SETTINGS },
            })
          );
        }
        return of(
          actions.updateReportUserSettingsFailed({
            payload: {
              error: 'FEHLER',
            },
          })
        );
      })
    )
  );

  updateDietDisplayOrderSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.updateDietDisplayOrderSettings),
      switchMap((action) =>
        this._kitchenSettingsService
          .updateDietDisplayOrder(action.payload)
          .pipe(
            map((response) => {
              this._toastMessageService.success(
                'Kostformenreihenfolge gespeichert'
              );
              return actions.updateDietDisplayOrderSettingsSuccess({
                payload: response,
              });
            }),
            catchError((error) => {
              this._toastMessageService.error(
                'Kostformenreihenfolge konnte nicht geändert werden',
                error.message
              );
              return of(
                actions.updateDietDisplayOrderSettingsError({
                  payload: { error },
                })
              );
            })
          )
      )
    )
  );

  //#region nutritional settings
  getNutritionalUserSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(
        actions.getNutritionalUserSettings,
        actions.updateNutritionalUserSettingsSuccess
      ),
      switchMap(() =>
        this._userSettingsService
          .getUserSettings<NutritionalUserSettings>('NutritionalUserSettings')
          .pipe(
            map((response) =>
              actions.getNutritionalUserSettingsSuccess({
                payload: { settings: response },
              })
            ),
            catchError((error: HttpErrorResponse) => {
              if (error.status === HttpStatusCode.NOT_FOUND) {
                // if no nutritional settings were found, use default value
                return of(
                  actions.getNutritionalUserSettingsSuccess({
                    payload: {
                      settings: {
                        displayFormat:
                          NutritionalUserSettingsDisplayFormatEnum.Portion,
                      },
                    },
                  })
                );
              } else {
                this._toastMessageService.error(
                  'Das Nährwerteformat konnte nicht geladen werden',
                  `${error.message}. Es wird das Standardformat verwendet.`
                );
                return of(actions.getNutritionalUserSettingsFailure());
              }
            })
          )
      )
    )
  );

  updateNutritionalUserSettings$ = createEffect(() =>
    this._actions.pipe(
      ofType(actions.updateNutritionalUserSettings),
      switchMap((action) =>
        this._userSettingsService
          .updateOrCreateUserSettings<NutritionalUserSettings>(
            'NutritionalUserSettings',
            action.payload.settings
          )
          .pipe(
            map(() => {
              this._toastMessageService.success(
                'Nährwerteformat erfolgreich aktualisiert.'
              );
              return actions.updateNutritionalUserSettingsSuccess({
                payload: {
                  settings: action.payload.settings,
                },
              });
            }),
            catchError((error: HttpErrorResponse) => {
              this._toastMessageService.error(
                'Das Nährwerteformat konnte gespeichert werden',
                error.message
              );
              return of(actions.updateNutritionalUserSettingsFailure());
            })
          )
      )
    )
  );
  //#end region nutritional settings
}
