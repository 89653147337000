import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum DisplayType {
  Tablet,
  Mobile,
  Desktop,
}

@Injectable({
  providedIn: 'root',
})
export class DisplayTypeService {
  // overlay slim static
  layoutMode = 'slim';

  public displayType$ = new BehaviorSubject<DisplayType>(DisplayType.Desktop);

  private readonly _mobileBreakpoints = ['(max-width:767.98px)'];
  private readonly _tabletBreakpoints = [
    '(min-width:768px) and (max-width:1024px)',
  ];
  private readonly _desktopBreakpoints = [
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ];

  constructor(private _breakpointObserver: BreakpointObserver) {
    let initialState: DisplayType;
    if (_breakpointObserver.isMatched(this._mobileBreakpoints)) {
      initialState = DisplayType.Mobile;
    } else if (_breakpointObserver.isMatched(this._tabletBreakpoints)) {
      initialState = DisplayType.Tablet;
    } else {
      initialState = DisplayType.Desktop;
    }
    this.displayType$ = new BehaviorSubject<DisplayType>(initialState);
    this._breakpointObserver
      .observe(this._mobileBreakpoints)
      .subscribe((isHandset) => {
        if (isHandset.matches) this.displayType$.next(DisplayType.Mobile);
      });
    this._breakpointObserver
      .observe(this._tabletBreakpoints)
      .subscribe((isTablet) => {
        if (isTablet.matches) this.displayType$.next(DisplayType.Tablet);
      });
    this._breakpointObserver
      .observe(this._desktopBreakpoints)
      .subscribe((isDesktop) => {
        if (isDesktop.matches) this.displayType$.next(DisplayType.Desktop);
      });
  }

  isTablet(): boolean {
    return this._breakpointObserver.isMatched(this._tabletBreakpoints);
  }

  isDesktop(): boolean {
    return this._breakpointObserver.isMatched(this._desktopBreakpoints);
  }

  isMobile(): boolean {
    return this._breakpointObserver.isMatched(this._mobileBreakpoints);
  }

  isStatic(): boolean {
    return this.layoutMode === 'static';
  }

  isOverlay(): boolean {
    return this.layoutMode === 'overlay';
  }

  isHorizontal(): boolean {
    return this.layoutMode === 'horizontal';
  }
  isSlim(): boolean {
    return this.layoutMode === 'slim';
  }
}
