<div>
  <p>{{ dialogParameters.message }}</p>
</div>
<div class="dialog-content-footer">
  <button
    type="button"
    pButton
    icon="fa-regular fa-check"
    (click)="closeDialog()"
    label="Ok"
  ></button>
</div>
