import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  private readonly httpClient = inject(HttpClient);

  termsAndConditions$ = this.httpClient.get(
    'assets/html/terms_and_conditions.html',
    { responseType: 'text' }
  );
}
