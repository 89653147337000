import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MainComponent } from '../main/main.component';
import { DisplayTypeService } from '../../../displaytype.service';
import { MenuService } from '../../services/menu.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mmu-nav-menu-item]',
  templateUrl: './nav-menu-item.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.layout-root-menuitem]': 'root',
    '[class.active-menuitem]': 'active',
  },
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuItemComponent implements OnInit, OnDestroy {
  @Input() item!: MenuItem;
  @Input() index!: number;
  @Input() root!: boolean;
  @Input() parentKey!: string;
  active = false;
  menuSourceSubscription!: Subscription;
  menuResetSubscription!: Subscription;
  key!: string;

  constructor(
    public mainComponent: MainComponent,
    public router: Router,
    private menuService: MenuService,
    private displayTypeService: DisplayTypeService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (key) => {
        // deactivate current active menu
        if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
          this.active = false;
        }
      }
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (
          this.displayTypeService.isHorizontal() ||
          this.displayTypeService.isSlim()
        ) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          } else {
            this.active = false;
          }
        }
      });
  }

  ngOnInit(): void {
    if (
      !(
        this.displayTypeService.isHorizontal() ||
        this.displayTypeService.isSlim()
      ) &&
      this.item.routerLink
    ) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);
  }

  updateActiveStateFromRoute(): void {
    this.active = this.router.isActive(
      this.item.routerLink[0],
      this.item.items ? false : true
    );
  }

  itemClick(event: Event): boolean {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.mainComponent.menuHoverActive = !this.mainComponent.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      // hide overlay menus
      if (this.displayTypeService.isMobile()) {
        this.mainComponent.overlayMenuActive = false;
        this.mainComponent.staticMenuMobileActive = false;
      }

      // reset horizontal menu
      if (
        this.displayTypeService.isHorizontal() ||
        this.displayTypeService.isSlim()
      ) {
        this.menuService.reset();
      }

      this.mainComponent.menuHoverActive = false;

      const ink = this.getInk(event.currentTarget);
      if (ink) {
        this.removeClass(ink, 'p-ink-active');
      }
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  getInk(el: any): any {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < el.children.length; i++) {
      if (
        typeof el.children[i].className === 'string' &&
        el.children[i].className.indexOf('p-ink') !== -1
      ) {
        return el.children[i];
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  removeClass(element: any, className: any): void {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  onMouseEnter(): void {
    // activate item on hover
    if (
      this.root &&
      this.mainComponent.menuHoverActive &&
      (this.displayTypeService.isHorizontal() ||
        this.displayTypeService.isSlim()) &&
      this.displayTypeService.isDesktop()
    ) {
      this.menuService.onMenuStateChange(this.key);
      this.active = true;
    }
  }

  ngOnDestroy(): void {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
