import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StringService {
  shortenLongWords(message: string): string {
    return message
      .split(/(\s+)/)
      .filter((e) => e.trim().length > 0)
      .map((word) =>
        word.length > 30 ? word.substring(0, 30).concat('...') : word
      )
      .join(' ');
  }
}
