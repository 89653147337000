<div class="grid justify-content-center not-found-page">
  <div class="col-8">
    <p-card>
      <div class="not-found-page-card-content">
        <div class="flex justify-content-center">
          <img
            class="not-found-page-icon"
            src="assets/logos/tg_quer_2D_cmyk_logo.svg"
          />
          <span class="not-found-page-mm-font">MenüManager</span>
        </div>

        <div class="flex justify-content-center">
          <p class="not-found-page-number">404</p>
        </div>
        <div class="flex justify-content-center">
          <h1>Seite nicht gefunden.</h1>
        </div>
        <div class="flex justify-content-center">
          <span>
            Die von Ihnen angeforderte Seite gibt es nicht.
            <br /><br />
            Nutzen Sie die Zurück-Taste Ihres Browsers oder klicken Sie auf den
            Button um auf die zuletzt besuchte Seite zurück zu kommen.
          </span>
        </div>

        <div class="flex flex-row-reverse justify-content-end">
          <p-button
            styleClass="not-found-page-back-button"
            icon="fa-regular fa-arrow-left"
            label="Zurück"
            (onClick)="previousPage()"
          >
          </p-button>
        </div>
      </div>
    </p-card>
  </div>
</div>
