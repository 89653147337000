import { Pipe, PipeTransform } from '@angular/core';
import { InventoryItemPriceDto } from '@mmu/app/inventory/domain';

@Pipe({
  name: 'inventorySelectedItem',
  pure: false,
})
export class InventorySelectedItemPipe implements PipeTransform {
  transform(value: InventoryItemPriceDto[]): InventoryItemPriceDto | undefined {
    if (!value) {
      return undefined;
    }

    return value?.find((price) => price?.selected === true) ?? value[0];
  }
}
