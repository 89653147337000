<ng-template #profileTemplate>
  <div class="flex flex-column flex-column-gap">
    <p-card header="Profilinformationen">
      <p>Anmeldename: {{ loginName }}</p>
      <p>Benutzername: {{ userName }}</p>
      <p>E-Mail: {{ (profile$ | async)?.emailAddress }}</p>
      <p>Kunde: {{ (profile$ | async)?.kitchenName }}</p>
    </p-card>
    <p-card header="Supportinformationen">
      <p>
        Menümanager Service Version:
        {{ (profile$ | async)?.serviceVersion }}
      </p>
      <p>Serverzeit: {{ serverTime$ | async }}</p>
      <p>Clientzeit: {{ clientTime$ | async }}</p>
      <p>
        Ticketsystem:
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://sanalogic.atlassian.net/servicedesk/customer/portal/7"
          >MenüManager Support Ticketsystem</a
        >
      </p>
      <p>Telefonnummer: <a href="tel:08001733733900">0800 1733733-900</a></p>
      <p>
        E-Mail:
        <a href="mailto:support@menumanager.transgourmet.de?subject=MenüManager"
          >support&#64;menumanager.transgourmet.de</a
        >
      </p>
    </p-card>
  </div>
</ng-template>

<ng-container *ngIf="profileIsPending$ | async; else profileTemplate">
  <div class="flex flex-column flex-column-gap">
    <p-card>
      <div class="flex flex-column flex-column-gap">
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
      </div>
    </p-card>
    <p-card>
      <div class="flex flex-column flex-column-gap">
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
        <p-skeleton width="30%" height="2rem"></p-skeleton>
      </div>
    </p-card>
  </div>
</ng-container>
