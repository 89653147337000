import { NotificationListContainerComponent } from './notification-list/notification-list-container.component';
import { NotificationListViewComponent } from './notification-list/notification-list-view.component';
import { NotificationCenterViewComponent } from './notification-center/notification-center-view.component';
import { NotificationCenterContainerComponent } from './notification-center/notification-center-container.component';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './../domain/data-access/notification.effects';
import { NotificationDomainModule } from '../domain/notification-domain.module';

@NgModule({
  declarations: [
    NotificationCenterContainerComponent,
    NotificationCenterViewComponent,
    NotificationListContainerComponent,
    NotificationListViewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NotificationDomainModule,
    EffectsModule.forFeature([NotificationEffects]),
  ],
  exports: [
    NotificationCenterContainerComponent,
    NotificationListContainerComponent,
  ],
})
export class NotificationFeaturesModule {}
