import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ViewChild,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { getWeek, lastDayOfWeek, startOfWeek } from 'date-fns';

export enum CalendarStatus {
  INIT,
  SELECTED,
}

@Component({
  selector: 'mmu-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekPickerComponent implements OnInit {
  @Input()
  get weekPickerDate(): Date {
    return this._weekPickerDate;
  }
  set weekPickerDate(weekPickerDate: Date) {
    this._weekPickerDate = weekPickerDate;
    this.selectDate(weekPickerDate, false, false);
  }
  private _weekPickerDate: Date = new Date();

  @Input() weekPickerMinDate!: Date;
  @Input() weekPickerMaxDate!: Date;

  @Output() datesChanged = new EventEmitter<[Date, Date, number, number]>();

  @ViewChild('weekpicker', { static: true }) weekpicker!: Calendar;

  weekPickerDates!: Array<Date>;
  weekPickerWeekNumber!: number;

  ngOnInit(): void {
    this.initCalendar();
  }

  selectDate(
    item: Date,
    hide: boolean = true,
    emitChangeEvent: boolean = true
  ): void {
    if (hide) {
      this.hideWeekPicker();
    }

    const selectedDate = new Date(item);
    const weekNumberFromSelection = getWeek(selectedDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    });
    const weekStartDateFromSelection = startOfWeek(selectedDate, {
      weekStartsOn: 1,
    });
    const weekEndDateFromSelection = lastDayOfWeek(selectedDate, {
      weekStartsOn: 1,
    });

    this.changeDates(
      weekStartDateFromSelection,
      weekEndDateFromSelection,
      weekNumberFromSelection,
      CalendarStatus.SELECTED,
      emitChangeEvent
    );
  }

  private initCalendar() {
    const minDate = startOfWeek(this.weekPickerMinDate, { weekStartsOn: 1 });
    const maxDate = lastDayOfWeek(this.weekPickerMaxDate, { weekStartsOn: 1 });

    const selectedDate = this.weekPickerDate;
    const weekNumberFromSelection = getWeek(selectedDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    });
    const weekStartDateFromSelection = startOfWeek(selectedDate, {
      weekStartsOn: 1,
    });
    const weekEndDateFromSelection = lastDayOfWeek(selectedDate, {
      weekStartsOn: 1,
    });

    this.changeEnabledDates(minDate, maxDate);
    this.changeDates(
      weekStartDateFromSelection,
      weekEndDateFromSelection,
      weekNumberFromSelection,
      CalendarStatus.INIT
    );
  }

  private changeDates(
    startDate: Date,
    endDate: Date,
    weekNumber: number,
    calendarStatus: number,
    emitChangeEvent: boolean = true
  ) {
    this.weekPickerDates = [startDate, endDate];
    this.weekPickerWeekNumber = weekNumber;
    if (emitChangeEvent) {
      this.datesChanged.emit([startDate, endDate, weekNumber, calendarStatus]);
    }
  }
  private changeEnabledDates(weekPickerMinDate: Date, weekPickerMaxDate: Date) {
    this.weekPickerMinDate = weekPickerMinDate;
    this.weekPickerMaxDate = weekPickerMaxDate;
  }

  private hideWeekPicker() {
    this.weekpicker.overlayVisible = false;
  }
}
