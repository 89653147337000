import { Injectable } from '@angular/core';
import { AuthenticationV2Service, LoginData } from '@sanalogic/ui-core/login';

@Injectable({ providedIn: 'root' })
export class AuthenticationStateService {
  private _loginData?: LoginData;
  private _username?: string;
  constructor(
    private readonly _authenticationService: AuthenticationV2Service
  ) {
    this._authenticationService.loginData$.subscribe(
      (data) => (this._loginData = data)
    );
    this._authenticationService.username$.subscribe(
      (name) => (this._username = name)
    );
  }

  getUsername(): string | undefined {
    return this._username;
  }

  getFullName(): string | undefined {
    return this._loginData?.userInformation?.name;
  }
}
