import { Injectable } from '@angular/core';
import { DialogParameters } from '../models/dialog-parameters';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ConfirmDialogViewComponent,
  ErrorDialogViewComponent,
  InfoDialogViewComponent,
  OrderDialogViewComponent,
  RequirementDialogViewComponent,
} from '../../dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  private static readonly dialogWidth = '400px';

  private errorDialog$ = new BehaviorSubject<DynamicDialogRef | null>(null);
  private infoDialog$ = new BehaviorSubject<DynamicDialogRef | null>(null);
  private confirmDialog$ = new BehaviorSubject<DynamicDialogRef | null>(null);
  private orderDialog$ = new BehaviorSubject<DynamicDialogRef | null>(null);
  private requirementDialog$ = new BehaviorSubject<DynamicDialogRef | null>(
    null
  );

  constructor(private primeNgDialogService: DialogService) {}

  public showError(dialogParameters: DialogParameters): void {
    const dialog = this.errorDialog$.value;
    if (dialog) {
      this.closeDialog(dialog);
    }

    this.errorDialog$.next(
      this.primeNgDialogService.open(ErrorDialogViewComponent, {
        header: dialogParameters.title,
        width: DialogsService.dialogWidth,
        data: dialogParameters,
      })
    );
  }

  public showInfo(dialogParameters: DialogParameters): void {
    const dialog = this.infoDialog$.value;
    if (dialog) {
      this.closeDialog(dialog);
    }
    this.infoDialog$.next(
      this.primeNgDialogService.open(InfoDialogViewComponent, {
        header: dialogParameters.title,
        width: DialogsService.dialogWidth,
        data: dialogParameters,
      })
    );
  }

  public showConfirm(dialogParameters: DialogParameters): Observable<boolean> {
    const dialog = this.confirmDialog$.value;
    if (dialog) {
      this.closeDialog(dialog);
    }
    const confirmDialog = this.primeNgDialogService.open(
      ConfirmDialogViewComponent,
      {
        header: dialogParameters.title,
        width: DialogsService.dialogWidth,
        data: dialogParameters,
      }
    );
    this.confirmDialog$.next(confirmDialog);
    return confirmDialog.onClose;
  }

  public showOrder(dialogParameters: DialogParameters): void {
    const dialog = this.orderDialog$.value;
    if (dialog) {
      this.closeDialog(dialog);
    }
    const orderDialog = this.primeNgDialogService.open(
      OrderDialogViewComponent,
      {
        header: dialogParameters.title,
        width: DialogsService.dialogWidth,
        data: dialogParameters,
      }
    );
    this.orderDialog$.next(orderDialog);
  }

  public showRequirement(dialogParameter: DialogParameters): void {
    const dialog = this.requirementDialog$.value;
    if (dialog) {
      this.closeDialog(dialog);
    }
    const requirementDialog = this.primeNgDialogService.open(
      RequirementDialogViewComponent,
      {
        header: dialogParameter.title,
        width: DialogsService.dialogWidth,
        data: dialogParameter,
      }
    );
    this.requirementDialog$.next(requirementDialog);
  }

  private closeDialog(dialog: DynamicDialogRef): void {
    if (dialog) {
      dialog.close();
    }
  }
}
