import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  settingsFeature,
  SettingsState,
} from '../data-access/settings.reducer';
import { SettingsState as CoreSettingsState } from '../../../core/data-access/reducers/settings.reducer';
import * as coreActions from '../../../core/data-access/actions/settings.actions';
import * as actions from '../data-access/settings.actions';
import {
  DietAllocationDto,
  NutritionalUserSettings,
  ReportingUserSettings,
} from '../models';
import { DietKitchenMappingBaseDto } from '../models/diet-kitchen-mappingb.dto';
import { AGBSettingsDto } from '../models/agb-settings.dto';

@Injectable({ providedIn: 'root' })
export class SettingsFacadeService {
  //#region diet allocations
  addedDietAllocation$ = this.store.select(
    settingsFeature.selectAddedDietAllocation
  );
  addedDietAllocationHttpError$ = this.store.select(
    settingsFeature.selectAddedDietAllocationHttpError
  );
  dietAllocations$ = this.store.select(settingsFeature.selectDietAllocations);
  dietAllocationsHttpError$ = this.store.select(
    settingsFeature.selectDietAllocationsHttpError
  );
  removedDietAllocation$ = this.store.select(
    settingsFeature.selectRemovedDietAllocation
  );
  removedDietAllocationHttpError$ = this.store.select(
    settingsFeature.selectRemovedDietAllocationHttpError
  );
  removedDietAllocationName$ = this.store.select(
    settingsFeature.selectRemovedDietAllocationName
  );
  removedDietAllocationNameHttpError$ = this.store.select(
    settingsFeature.selectRemovedDietAllocationNameHttpError
  );
  updatedDietAllocationName$ = this.store.select(
    settingsFeature.selectUpdatedDietAllocationName
  );
  updatedDietAllocationNameHttpError$ = this.store.select(
    settingsFeature.selectUpdatedDietAllocationNameHttpError
  );

  //#endregion

  //#region diet settings
  dietDisplayOrderSettings$ = this.store.select(
    settingsFeature.selectDietDisplayOrder
  );

  dietDisplayOrderSettingsHttpError$ = this.store.select(
    settingsFeature.selectDietDisplayOrderError
  );

  //#endregion

  //#region report settings
  reportingUserSettings$ = this.store.select(
    settingsFeature.selectReportingUserSettings
  );
  //#endregion

  //#region agb settings
  AGBSettings$ = this.store.select(settingsFeature.selectAGBSettings);
  //#endregion agb settings

  //#region nutritional settings
  userNutritionalUserSettings$ = this.store.select(
    settingsFeature.selectNutritionalUserSettings
  );
  //#end region nutritional settings

  constructor(
    private store: Store<SettingsState>,
    private coreSettingStore: Store<CoreSettingsState>
  ) {}

  addDietAllocation(dietAllocation: DietAllocationDto): void {
    this.store.dispatch(
      actions.addDietAllocation({
        payload: {
          dietAllocation: { ...dietAllocation },
        },
      })
    );
  }

  getDietAllocations(): void {
    this.store.dispatch(actions.getDietAllocations());
  }

  removedDietAllocation(dietAllocation: DietAllocationDto): void {
    this.store.dispatch(
      actions.removeDietAllocation({
        payload: {
          dietAllocation: { ...dietAllocation },
        },
      })
    );
  }

  removedDietAllocationName(dietAllocation: DietAllocationDto): void {
    this.store.dispatch(
      actions.removeDietAllocationName({
        payload: {
          dietAllocation: { ...dietAllocation },
        },
      })
    );
  }

  updatedDietAllocationName(dietAllocation: DietAllocationDto): void {
    this.store.dispatch(
      actions.updateDietAllocationName({
        payload: {
          dietAllocation: { ...dietAllocation },
        },
      })
    );
  }

  updateDietDisplaySettings(
    displaySettings: DietKitchenMappingBaseDto[]
  ): void {
    this.store.dispatch(
      actions.updateDietDisplayOrderSettings({ payload: displaySettings })
    );
  }

  getReportUserSettings(): void {
    this.store.dispatch(actions.getReportUserSettings());
  }

  updateReportUserSettings(reportUserSettings: ReportingUserSettings): void {
    this.store.dispatch(
      actions.updateReportUserSettings({ payload: { reportUserSettings } })
    );
  }

  getAGBSettings(): void {
    this.store.dispatch(actions.getAGBSettings());
  }

  updateAGBSettings(AGBSettings: AGBSettingsDto): void {
    this.store.dispatch(
      actions.updateAGBSettings({ payload: { AGBSettings } })
    );
  }

  loadCoreSettings(): void {
    this.coreSettingStore.dispatch(coreActions.loadSettings());
  }

  //#region nutritional settings
  getNutritionalUserSettings(): void {
    this.store.dispatch(actions.getNutritionalUserSettings());
  }

  updateNutritionalUserSettings(
    nutritionalUserSettings: NutritionalUserSettings
  ): void {
    this.store.dispatch(
      actions.updateNutritionalUserSettings({
        payload: { settings: nutritionalUserSettings },
      })
    );
  }
  //#end region nutritional settings
}
