import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '../services';

@Injectable()
export class AntiforgeryTokenInterceptor implements HttpInterceptor {
  constructor(private readonly _environmentService: EnvironmentService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const clonedRequest = request.clone();
    return next.handle(request).pipe(
      catchError((response) => {
        if (response?.error === 'InvalidAntiForgeryToken') {
          return this._environmentService
            .getXsrfToken()
            .pipe(switchMap(() => next.handle(clonedRequest)));
        }
        return throwError(response);
      })
    );
  }
}
