import { MenuManagerSettings } from '../../models/menuManagerSettings';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const loadSettings = createAction('[Settings] Load settings');

export const loadSettingsSuccess = createAction(
  '[Settings] Load settings success',
  props<{ payload: { settings: MenuManagerSettings } }>()
);

export const loadSettingsFail = createAction(
  '[Settings] Load Settings fail',
  props<{ payload: { error: HttpErrorResponse } }>()
);
