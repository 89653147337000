import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogParameters } from '../../../core/models/dialog-parameters';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'mmu-info-dialog-view',
  templateUrl: './info-dialog-view.component.html',
  styleUrls: ['./info-dialog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogViewComponent implements OnInit {
  dialogParameters!: DialogParameters;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.dialogParameters = this.config.data as DialogParameters;
  }

  closeDialog(): void {
    this.ref.close();
  }
}
