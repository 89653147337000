import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router,
  NavigationEnd,
  NavigationBehaviorOptions,
} from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly history = new Array<string>();
  private routingSubsciption!: Subscription;

  constructor(private router: Router, private location: Location) {
    this.subscibeToRoutingHistory();
  }

  subscibeToRoutingHistory(): void {
    if (!this.routingSubsciption) {
      this.routingSubsciption = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }
      });
    }
  }

  navigateBack(popHistory: boolean = true, target?: string): void {
    if (popHistory) {
      this.history.pop();
    }
    if (this.history.length > 0) {
      if (target && target?.length > 0) {
        this.navigateByUrl(target ?? '/');
      } else {
        this.location.back();
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  navigateToPageNotFound(): void {
    this.router.navigateByUrl('/page-not-found', { skipLocationChange: true });
  }

  navigateToPageForbidden(): void {
    this.router.navigateByUrl('/page-forbidden', { skipLocationChange: true });
  }

  navigateByUrl(
    url: string,
    extras: NavigationBehaviorOptions = { skipLocationChange: false }
  ): void {
    this.router.navigateByUrl(`/${url}`, extras);
  }

  getLastUrlRoute(): string {
    if (this.history?.length > 1) {
      return this.history[this.history.length - 2];
    } else {
      return '';
    }
  }
}
