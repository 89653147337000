<div
  [ngClass]="{ sticky: isSticky }"
  class="page-header p-card flex flex-column flex-column-gap"
>
  <div
    class="flex flex-row flex-row-gap justify-content-between align-items-center"
  >
    <ng-container *ngIf="title">
      <div
        class="overflow-ellipsis page-header-title flex flex-row align-items-center"
      >
        <ng-container *ngIf="iconClass.length > 0">
          <i [ngClass]="iconClass"></i>
        </ng-container>
        <span class="overflow-ellipsis"> {{ title }}</span>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    <ng-container *ngIf="showBackButton">
      <button
        pButton
        mmuBackButton
        [url]="targetUrl"
        type="button"
        label="zurück"
        icon="fa-solid fa-angle-left"
        class="p-button-text font-bold"
        [disabled]="disableBackButton"
      ></button>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="content.length > 0">
    <div class="page-header-content">
      {{ content }}
    </div>
  </ng-container>
</div>
