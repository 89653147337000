<div class="flex flex-wrap column-gap-mark">
  <!-- Wrapper for in-menuplan-icon -->
  <ng-container *ngIf="showMenuplanIcon">
    <div
      class="order-mark"
      pTooltip="Im Speiseplan"
      tooltipPosition="right"
      placeholder="Left"
    >
      <i class="fa-regular fa-calendar"></i>
    </div>
  </ng-container>
  <!-- Wrapper for used-in-own-components-icon -->
  <ng-container *ngIf="isUsedInOwnComponent">
    <div
      class="order-mark"
      pTooltip="In eigenen Rezepten"
      tooltipPosition="right"
      placeholder="Left"
    >
      <i class="fa-solid fa-book"></i>
    </div>
  </ng-container>
  <!-- Wrapper for user-edit-icon -->
  <ng-container *ngIf="showUserEditIcon">
    <div
      class="order-mark"
      pTooltip="Vom Kunden verwaltet"
      tooltipPosition="right"
      placeholder="Left"
    >
      <i class="fa-regular fa-user-pen"></i>
    </div>
  </ng-container>
  <!-- Wrapper for warehouse status icon -->
  <ng-container
    *ngIf="
      (getAllowLockIndicators() | async) &&
      (warehouseStatus?.isCanceled ||
        warehouseStatus?.isDeleted ||
        warehouseStatus?.isLocked ||
        isArticleLocked)
    "
  >
    <div class="order-mark">
      <i
        [pTooltip]="getWarehouseStatusTooltip()"
        tooltipZIndex="2147483647"
        class="fa-solid fa-exclamation color-mm-red"
        aria-hidden="true"
      ></i>
    </div>
  </ng-container>
  <!-- Wrapper for pricetype-icons -->
  <ng-container *ngIf="(priceType?.priceType | priceTypeInfo).length">
    <div class="order-mark-img order-mark">
      <div
        pTooltip="{{ priceType?.priceType | priceTypeInfo }}"
        tooltipPosition="right"
        placeholder="Left"
      >
        {{ this.priceTypeShort }}
      </div>
    </div>
  </ng-container>
  <!-- Wrapper for manual-order-icon -->
  <ng-container *ngIf="isManualOrder">
    <div
      class="order-mark"
      pTooltip="Artikel wird bei der Bedarfsanforderung nicht übergeben und muss gesondert bestellt werden."
      tooltipPosition="right"
      placeholder="Left"
    >
      M
    </div>
  </ng-container>
  <!-- Wrapper for sub-recipe-icon -->
  <ng-container *ngIf="showSubRecipe">
    <div class="order-mark">
      <i
        class="fa-solid fa-book"
        pTooltip="Bei dieser Position handelt es sich um ein Unterrezept."
        tooltipPosition="right"
        placeholder="Left"
      ></i>
    </div>
  </ng-container>
  <!-- Wrapper for sub-recipe problem icon-->
  <ng-container *ngIf="componentProblem">
    <div class="order-mark">
      <i
        class="fa-solid fa-exclamation color-mm-red"
        pTooltip="Bei diesem Rezept gibt es ein Problem."
        aria-hidden="true"
      ></i>
    </div>
  </ng-container>
</div>
