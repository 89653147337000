import { HttpErrorResponse } from '@angular/common/http';
import { createFeature, createReducer, on } from '@ngrx/store';
import { DietAllocationDto } from '..';
import {
  ReportingUserSettings,
  NutritionalUserSettings,
  NutritionalUserSettingsDisplayFormatEnum,
} from '../models';
import { DietKitchenMappingBaseDto } from '../models/diet-kitchen-mappingb.dto';
import * as actions from './settings.actions';
import { AGBSettingsDto } from '../models/agb-settings.dto';

export interface SettingsState {
  date: Date | null;
  dietAllocations: DietAllocationDto[] | null;
  dietAllocationsHttpError: HttpErrorResponse | null;
  addedDietAllocation: DietAllocationDto | null;
  addedDietAllocationHttpError: HttpErrorResponse | null;
  updatedDietAllocationName: DietAllocationDto | null;
  updatedDietAllocationNameHttpError: HttpErrorResponse | null;
  removedDietAllocation: DietAllocationDto | null;
  removedDietAllocationHttpError: HttpErrorResponse | null;
  removedDietAllocationName: DietAllocationDto | null;
  removedDietAllocationNameHttpError: HttpErrorResponse | null;
  dietDisplayOrder: DietKitchenMappingBaseDto[] | null;
  dietDisplayOrderError: HttpErrorResponse | null;
  reportingUserSettings: ReportingUserSettings | null;
  nutritionalUserSettings: NutritionalUserSettings | null;
  AGBSettings: AGBSettingsDto | null;
}

export const initialState: SettingsState = {
  date: null,
  dietAllocations: null,
  dietAllocationsHttpError: null,
  addedDietAllocation: null,
  addedDietAllocationHttpError: null,
  updatedDietAllocationName: null,
  updatedDietAllocationNameHttpError: null,
  removedDietAllocation: null,
  removedDietAllocationHttpError: null,
  removedDietAllocationName: null,
  removedDietAllocationNameHttpError: null,
  dietDisplayOrder: null,
  dietDisplayOrderError: null,
  reportingUserSettings: null,
  nutritionalUserSettings: null,
  AGBSettings: null,
};

export const settingsFeature = createFeature({
  name: 'usersettings',
  reducer: createReducer(
    initialState,
    //reset state
    on(actions.resetState, () => ({
      ...initialState,
    })),
    //#region diet allocations
    on(actions.getDietAllocations, (state: SettingsState) => ({
      ...state,
      dietAllocations: null,
      dietAllocationsHttpError: null,
    })),
    on(
      actions.getDietAllocationsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        dietAllocations: payload.dietAllocations,
      })
    ),
    on(
      actions.getDietAllocationsFailed,
      (state: SettingsState, { payload }) => ({
        ...state,
        dietAllocationsHttpError: payload.error,
      })
    ),

    on(actions.addDietAllocation, (state: SettingsState) => ({
      ...state,
      addedDietAllocation: null,
      addedDietAllocationHttpError: null,
    })),
    on(
      actions.addDietAllocationSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        addedDietAllocation: payload.dietAllocation,
      })
    ),
    on(
      actions.addDietAllocationFailed,
      (state: SettingsState, { payload }) => ({
        ...state,
        addedDietAllocationHttpError: payload.error,
      })
    ),

    on(actions.updateDietAllocationName, (state: SettingsState) => ({
      ...state,
      updatedDietAllocationName: null,
      updatedDietAllocationNameHttpError: null,
    })),
    on(
      actions.updateDietAllocationNameSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        updatedDietAllocationName: payload.dietAllocation,
      })
    ),
    on(
      actions.updateDietAllocationNameFailed,
      (state: SettingsState, { payload }) => ({
        ...state,
        updatedDietAllocationNameHttpError: payload.error,
      })
    ),

    on(actions.removeDietAllocation, (state: SettingsState) => ({
      ...state,
      removedDietAllocation: null,
      removedDietAllocationHttpError: null,
    })),
    on(
      actions.removeDietAllocationSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        removedDietAllocation: payload.dietAllocation,
      })
    ),
    on(
      actions.updateDietAllocationNameFailed,
      (state: SettingsState, { payload }) => ({
        ...state,
        removedDietAllocationHttpError: payload.error,
      })
    ),

    on(actions.removeDietAllocationName, (state: SettingsState) => ({
      ...state,
      removedDietAllocationName: null,
      removedDietAllocationNameHttpError: null,
    })),
    on(
      actions.removeDietAllocationNameSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        removedDietAllocationName: payload.dietAllocation,
      })
    ),
    on(
      actions.removeDietAllocationNameFailed,
      (state: SettingsState, { payload }) => ({
        ...state,
        removedDietAllocationNameHttpError: payload.error,
      })
    ),
    //#endregion

    //#region diet settings

    on(
      actions.updateDietDisplayOrderSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        dietDisplaySettings: payload,
      })
    ),

    on(
      actions.updateDietDisplayOrderSettingsError,
      (state: SettingsState, { payload }) => ({
        ...state,
        dietDisplayOrderError: payload.error,
      })
    ),

    //#endregion

    //#region report settings
    on(actions.getReportUserSettings, (state: SettingsState) => ({
      ...state,
    })),
    on(
      actions.getReportUserSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        reportingUserSettings: payload.reportUserSettings,
      })
    ),
    on(actions.getReportUserSettingsFailed, (state: SettingsState) => ({
      ...state,
    })),
    on(
      actions.updateReportUserSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        reportingUserSettings: payload.reportUserSettings,
      })
    ),

    //#region nutritional settings
    on(
      actions.getNutritionalUserSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        nutritionalUserSettings: payload.settings,
      })
    ),
    on(actions.getNutritionalUserSettingsFailure, (state: SettingsState) => ({
      ...state,
      nutritionalUserSettings: {
        displayFormat: NutritionalUserSettingsDisplayFormatEnum.Portion,
      },
    })),
    on(
      actions.updateNutritionalUserSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        nutritionalUserSettings: payload.settings,
      })
    ),
    on(
      actions.updateNutritionalUserSettingsFailure,
      (state: SettingsState) => ({
        ...state,
        nutritionalUserSettings: {
          displayFormat: NutritionalUserSettingsDisplayFormatEnum.Portion,
        },
      })
    ),
    //#end region nutritional settings

    //#region agb settings
    on(actions.getAGBSettings, (state: SettingsState) => ({
      ...state,
    })),
    on(actions.getAGBSettingsSuccess, (state: SettingsState, { payload }) => ({
      ...state,
      AGBSettings: payload.AGBSettings,
    })),
    on(actions.getAGBSettingsFailed, (state: SettingsState) => ({
      ...state,
    })),
    on(actions.updateAGBSettings, (state: SettingsState, { payload }) => ({
      ...state,
      AGBSettings: payload.AGBSettings,
    })),
    on(
      actions.updateAGBSettingsSuccess,
      (state: SettingsState, { payload }) => ({
        ...state,
        AGBSettings: payload.AGBSettings,
      })
    ),
    on(actions.updateAGBSettingsFailed, (state: SettingsState) => ({
      ...state,
    }))
    //#endregion agb settings
  ),
});

export const {
  selectUsersettingsState,
  selectDate,
  selectDietAllocations,
  selectDietAllocationsHttpError,
  selectAddedDietAllocation,
  selectAddedDietAllocationHttpError,
  selectUpdatedDietAllocationName,
  selectUpdatedDietAllocationNameHttpError,
  selectRemovedDietAllocation,
  selectRemovedDietAllocationHttpError,
  selectRemovedDietAllocationName,
  selectRemovedDietAllocationNameHttpError,
  selectReportingUserSettings,
  selectNutritionalUserSettings,
  selectAGBSettings,
} = settingsFeature;
