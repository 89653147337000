import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'mmu-info-dropdown',
  templateUrl: './info-dropdown.component.html',
  styleUrls: ['./info-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDropdownComponent {
  showMessage = false;

  private readonly regexMobile = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  @Input() iconClass = 'fa-solid fa-info-circle';
  @Input() tooltipText = 'pro Portion';

  @HostListener('document:click', ['$event'])
  clickout(event: PointerEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMessage = false;
    }
  }

  constructor(private eRef: ElementRef) {}

  isMobile(): boolean {
    return this.regexMobile.some((item) => navigator.userAgent.match(item));
  }

  showTooltip(): void {
    if (this.isMobile()) {
      this.showMessage = !this.showMessage;
    }
  }
}
