import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationFacade } from '../../domain/application/notification.facade';

@Component({
  selector: 'mmu-notification-list-container',
  template: `<mmu-notification-list-view
    [notifications]="(notifications$ | async) ?? []"
    [notificationsIsPending]="(notificationsPendingState$ | async) ?? false"
    (setNotificationReadEmitter)="setNotificationRead($event)"
  ></mmu-notification-list-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListContainerComponent implements OnInit {
  readonly notifications$ = this._notificationFacade.notifications$;
  readonly notificationsPendingState$ =
    this._notificationFacade.notificationsPendingState$;

  constructor(private readonly _notificationFacade: NotificationFacade) {}

  ngOnInit(): void {
    this._notificationFacade.loadNotificationList();
  }

  setNotificationRead(number: number): void {
    this._notificationFacade.setNotificationRead(number);
  }
}
