<ng-container *ngIf="$any(menuItems).length > 0; else emptyView">
  <span
    class="menu-button"
    style="align-self: flex-end"
    (click)="toggleMenu($event)"
  >
  </span>
  <p-menu
    #menu
    [popup]="true"
    [model]="$any(menuItems)"
    [styleClass]="styleClass"
    [appendTo]="appendTo"
    [autoZIndex]="false"
    [baseZIndex]="50"
  >
  </p-menu>
</ng-container>
<ng-template #emptyView>
  <span class="menu-button-locked" style="align-self: flex-end"></span>
</ng-template>
