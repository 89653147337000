import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DietAllocationDto } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly _httpClient: HttpClient) {}

  getDietAllocations(): Observable<DietAllocationDto[]> {
    const url = '/api/MenuManagerService/DietAllocations';
    return this._httpClient.get<DietAllocationDto[]>(url);
  }

  addDietAllocation(
    dietAllocation: DietAllocationDto
  ): Observable<DietAllocationDto> {
    const dietNumber = dietAllocation.number;
    const url = `/api/MenuManagerService/DietAllocations/${dietNumber}`;
    return this._httpClient.put<DietAllocationDto>(url, null);
  }

  removeDietAllocation(dietAllocation: DietAllocationDto): Observable<unknown> {
    const dietNumber = dietAllocation.number;
    const url = `/api/MenuManagerService/DietAllocations/${dietNumber}`;
    return this._httpClient.delete(url);
  }

  updateDietAllocationName(
    dietAllocation: DietAllocationDto
  ): Observable<DietAllocationDto> {
    const dietNumber = dietAllocation.number;
    const encodedCustomName = encodeURIComponent(dietAllocation.customName);
    const url = `/api/MenuManagerService/DietAllocations/Name/${dietNumber}`;
    return this._httpClient.put<DietAllocationDto>(url, null, {
      params: { customName: encodedCustomName },
    });
  }

  removeDietAllocationName(
    dietAllocation: DietAllocationDto
  ): Observable<unknown> {
    const dietNumber = dietAllocation.number;
    const url = `/api/MenuManagerService/DietAllocations/Name/${dietNumber}`;
    return this._httpClient.delete(url);
  }
}
