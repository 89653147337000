import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { ProfileEffects } from './domain/data-access/effects/profile.effects';
import { ProfilePageComponent } from './pages/profile/profile.page.component';
import { profileFeature } from './domain';
import { SettingsEffects } from '../settings/domain/data-access/settings.effects';
import { settingsFeature } from '../settings/domain/data-access/settings.reducer';
import { settingsFeature as coreSettingsFeature } from '../core/data-access/reducers/settings.reducer';
import { SettingsEffects as CoreSettingsEffect } from '../core/data-access/effects/settings.effects';
@NgModule({
  declarations: [ProfilePageComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    StoreModule.forFeature(profileFeature),
    StoreModule.forFeature(settingsFeature),
    StoreModule.forFeature(coreSettingsFeature),
    EffectsModule.forFeature([
      ProfileEffects,
      SettingsEffects,
      CoreSettingsEffect,
    ]),
    SharedModule,
  ],
})
export class ProfileModule {}
