import { createReducer } from '@ngrx/store';

export const coreFeatureKey = 'core';

export interface State {
  status: number;
}

const initialState: State = {
  status: 1,
};

export const reducer = createReducer(initialState);
