<ul class="layout-menu layout-main-menu clearfix">
  <li
    mmu-nav-menu-item
    *ngFor="let item of menuItems; let i = index"
    [item]="item"
    [index]="i"
    [root]="false"
    pTooltip="{{ item.label }}"
    tooltipStyleClass="mm-tooltip-slim"
  ></li>
</ul>
