<div class="ui-btn-bar">
  <button class="ui-btn" (click)="count(-1)" [disabled]="disabled">
    <i class="fa-solid fa-minus"></i>
  </button>
  <input
    class="ui-input"
    #inputField
    type="text"
    [value]="value"
    [attr.maxlength]="maxLength"
    (change)="onInputChange(inputField.value)"
    (input)="onInput()"
    (keydown)="onInputKeyPress($event)"
    (keypress)="isNumber($event)"
    (blur)="onInputBlur($event)"
    (focus)="onInputFocus($event)"
    [disabled]="disabled"
    [ngStyle]="inputStyle"
  />
  <button class="ui-btn" (click)="count(1)" [disabled]="disabled">
    <i class="fa-solid fa-plus"></i>
  </button>
</div>
