import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceDisplay',
})
export class PriceDisplayPipe implements PipeTransform {
  transform(value: string | null): string {
    return value ? value : 'N.V.';
  }
}
