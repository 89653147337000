export enum UserPrivilege {
  AllowEditKitchenSettings = 22,
  AllowPlanningToday = 80,
  DisplayInventoryModule = 960,
  DisplayDashboardModule = 972,
  DisplayMenuplanModule = 973,
  MenuplanOrderButton = 974,
  MenuplanReportButton = 975,
  MenuplanOrderabilityButton = 976,
  MenuplanDisplayPrices = 977,
  MenuplanDisplayCopyPaste = 978,
  MenuplanAddComponent = 979,
  MenuplanRemoveComponent = 980,
  DisplayComponentModule = 981,
  ComponentDisplayDetails = 982,
  ComponentDisplayPrepartiuonGuid = 983,
  ComponentEditPrepartiuonGuid = 984,
  ComponentEditRecipe = 985,
  ComponentEditDetails = 986,
  ComponentEditKeywords = 987,
  ComponentAddNew = 988,
  DisplayArticleModule = 989,
  ArticleSwapArticle = 990,
  DisplayDietSetting = 991,
  DisplaySwitchCustomer = 992,
  DisplayArticleMark = 993,
  UseApp = 997,
  DisplayNotificationSetting = 999,
  DisplayEditComponentsBreadUnit = 1000,
  DisplayEditComponentsServingSize = 1001,
  DisplayFoodRequirementModule = 1009,
  DisplayAssumePlanValue = 1010,
  DisplayProductionModule = 1011,
  DisplaySupplierModule = 1022,
  SupplierAddNew = 1023,
  SupplierEdit = 1024,
  ArticleAddNew = 1025,
  ArticleEdit = 1026,
  DisplayStorageLocationSettings = 1035,
  DisplayStorageLocations = 1036,
  EditOrderDeadline = 1048,
  DisplayAdminSettings = 10500,
}
