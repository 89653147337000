import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPageComponent } from './notification-page.component';
import { SharedModule } from '../../shared/shared.module';
import { NotificationFeaturesModule } from '../features/notification-features.module';
import { NotificationDomainModule } from '../domain/notification-domain.module';
import { NotificationCenterContainerComponent } from '../features/notification-center/notification-center-container.component';
import { NotificationListContainerComponent } from '../features/notification-list/notification-list-container.component';
import { NotificationsPagesRoutingModule } from './notification-page-routing.module';

@NgModule({
  declarations: [NotificationPageComponent],
  imports: [
    CommonModule,
    NotificationsPagesRoutingModule,
    NotificationDomainModule,
    NotificationFeaturesModule,
    SharedModule,
  ],
  exports: [
    NotificationPageComponent,
    NotificationCenterContainerComponent,
    NotificationListContainerComponent,
  ],
})
export class NotificationPagesModule {}
