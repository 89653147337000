import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { HttpStatusCode } from '../models';
import { StringService } from './string.service';

export enum ToastMessageEnum {
  FORWARD,
}

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(
    private readonly _messageService: MessageService,
    private readonly _stringService: StringService
  ) {}

  private readonly lifeTime = 4000;
  message$ = new BehaviorSubject<ToastMessageEnum | null>(null);

  success(detail: string, summary?: string): void {
    this._messageService.add({
      severity: 'success',
      summary: summary || '',
      detail: this._stringService.shortenLongWords(detail),
      key: 'global',
      life: this.lifeTime,
    });
  }

  toastMessageLinked(
    detail: string,
    url: string,
    urlLabel: string,
    summary?: string,
    severity = 'success',
    icon = 'fa-check'
  ): void {
    this._messageService.add({
      severity,
      summary: summary || '',
      detail: this._stringService.shortenLongWords(detail),
      key: 'global-link',
      life: this.lifeTime,
      data: {
        url: url,
        urlLabel: urlLabel,
        icon,
      },
    });
  }

  error(summary: string, detail?: string): void {
    this._messageService.add({
      severity: 'error',
      summary: summary,
      detail: this._stringService.shortenLongWords(detail ?? ''),
      key: 'global',
      life: this.lifeTime,
    });
  }

  warn(summary: string, detail?: string): void {
    this._messageService.add({
      severity: 'warn',
      summary: summary,
      detail: this._stringService.shortenLongWords(detail ?? ''),
      key: 'global',
      life: this.lifeTime,
    });
  }

  clear(): void {
    this._messageService.clear();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError({ err, data }: { err: HttpErrorResponse; data: any }): void {
    switch (err.status) {
      case HttpStatusCode.CONFLICT:
        this.warn('Warnung', `${data?.nameMenuPlan} ist bereits vorhanden.`);
    }
  }
}
