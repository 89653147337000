<ng-template #contentTemplate>
  <ul *ngIf="notifications.length > 0">
    <li
      *ngFor="let notification of notifications; trackBy: trackByIndex"
      [ngClass]="[
        'text-center',
        'notification',
        notification.isUnread ? 'unread' : 'read'
      ]"
      (click)="setNotificationRead(notification.number)"
    >
      <div class="grid">
        <div class="col-fixed col-align-center" style="width: 40px">
          <a *ngIf="notification.isUnread" class="unreadLink"
            ><span class="unreadBullet"></span>
          </a>
        </div>
        <div class="col-fixed col-align-center" style="width: 60px">
          <i class="fa-solid fa-info notification-icon"></i>
        </div>
        <div class="col">
          <a href="" [routerLink]="['/notifications']" (click)="close()">
            <div class="box color-mm-dark-gray">
              {{ notification.text }}
            </div>
          </a>
        </div>
        <div class="col-fixed">
          <b title="{{ notification.from }}">
            {{ notification.from | date: "dd.MM.yyyy" }}
          </b>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="notifications.length === 0">
    <span class="emptyNotificationList">Keine Nachrichten</span>
  </div>
</ng-template>

<ng-container *ngIf="notificationsIsPending; else contentTemplate">
  <div class="flex flex-column flex-column-gap">
    <p-skeleton width="100%" height="4rem"></p-skeleton>
  </div>
</ng-container>
