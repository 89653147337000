<i
  [class]="iconClass"
  [pTooltip]="!isMobile() ? tooltipText : ''"
  (click)="showTooltip()"
></i>

<div
  class="p-tooltip p-component p-tooltip-right"
  style="display: inline-block"
  *ngIf="showMessage"
>
  <div class="p-tooltip-arrow"></div>
  <div class="p-tooltip-text">{{ tooltipText }}</div>
</div>
