import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mmu-sidepanel',
  templateUrl: './sidepanel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidepanelComponent {
  @Input() sidepanelOpen = false;
  @Input() titel = '';
  @Input() width = '100%';
  @Output() toggle = new EventEmitter<boolean>();

  toggleSidepanel(): void {
    this.sidepanelOpen = !this.sidepanelOpen;
    this.toggle.emit(this.sidepanelOpen);
  }
}
