import { EnvironmentDto } from './../models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  protected env!: EnvironmentDto;

  constructor(private readonly _httpClient: HttpClient) {}

  getEnvironment(): Observable<EnvironmentDto | undefined> {
    return this._httpClient
      .get<EnvironmentDto>('api/MenuManagerService/Environment/GetEnvironment')
      .pipe(
        map((env: EnvironmentDto) => {
          if (env.name.length !== 0) {
            this.env = env;
            this.setEnvironmentToStorage(env);
          }
          return env;
        })
      );
  }

  getXsrfToken(): Observable<void> {
    return this._httpClient.get<void>(
      'api/MenuManagerService/Environment/GetXsrfToken'
    );
  }

  private setEnvironmentToStorage(env: EnvironmentDto): void {
    sessionStorage.setItem('currentEnvironment', JSON.stringify(env));
  }

  getEnvironmentFromStorage(): EnvironmentDto | undefined {
    if (sessionStorage.getItem('currentEnvironment') !== null) {
      const ENVIRONMENT = JSON.parse(
        sessionStorage.getItem('currentEnvironment') ?? ''
      );
      return ENVIRONMENT;
    }
    return undefined;
  }
}
