<ng-container *ngIf="dialogInputfield">
  <form [formGroup]="dialogInputfield" (ngSubmit)="setOriginFormValue()">
    <div class="flex flex-column flex-column-gap">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <textarea
            pInputText
            formControlName="valueInputForm"
            id="valueInput"
            type="text"
            class="ng-invalid ng-dirty"
            [ngClass]="{ 'ng-invalid ng-dirty': valueInput?.invalid }"
            class="mm-input-field"
            [rows]="rowsInputField"
            [cols]="colsInputField"
          >
          </textarea>
          <ng-container *ngIf="valueInput?.errors">
            <small id="name-required-error" class="p-error"
              >Maximal {{ maxLength }} Zeichen erlaubt
            </small>
          </ng-container>
        </div>
      </div>
      <div class="align-self-end">
        <p-button
          [label]="labelOfConfirmButton"
          icon="fa-regular fa-check"
          [disabled]="dialogInputfield.invalid"
          type="submit"
        ></p-button>
      </div>
    </div>
  </form>
</ng-container>
