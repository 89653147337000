<ng-container>
  <div *ngIf="root" class="layout-menuitem-root-text">{{ item.label }}</div>
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    *ngIf="!item.routerLink || item.items"
    (mouseenter)="onMouseEnter()"
    (keydown.enter)="itemClick($event)"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [ngClass]="item.styleClass ?? ''"
    pRipple
  >
    <!-- TODO AW 2021-04-06_08-33 icons not shown in static mode -->
    <i [ngClass]="item.icon ?? ''" class="layout-menuitem-icon"></i>
    <span>{{ item.label }}</span>
    <i
      class="fa-solid fa-angle-down menuitem-toggle-icon"
      *ngIf="item.items"
    ></i>
    <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  </a>
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    *ngIf="item.routerLink && !item.items"
    [routerLink]="item.routerLink"
    routerLinkActive="active-menuitem-routerlink"
    [ngClass]="item.styleClass ?? ''"
    pRipple
    [routerLinkActiveOptions]="{ exact: false }"
    [attr.target]="item.target"
    [attr.tabindex]="0"
  >
    <i [ngClass]="item.icon ?? ''" class="layout-menuitem-icon"></i>
    <span>{{ item.label }}</span>
    <i
      class="fa-regular fa-angle-down menuitem-toggle-icon"
      *ngIf="item.items"
    ></i>
    <span class="menuitem-badge" *ngIf="item.badge">{{ item.badge }}</span>
  </a>
  <div class="submenu-arrow" *ngIf="item.items"></div>
  <ul
    *ngIf="(item.items && root) || (item.items && active)"
    [@children]="
      root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'
    "
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        mmu-nav-menu-item
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [class]="child.badgeStyleClass"
      ></li>
    </ng-template>
  </ul>
</ng-container>
