import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const login = createAction(
  '[Login] User login',
  props<{
    payload: {
      username: string;
      password: string;
      redirectToKitchen: boolean;
    };
  }>()
);

export const loginSuccess = createAction(
  '[Login] User login success',
  props<{ payload: { status: number; redirectToKitchen: boolean } }>()
);

export const loginNotSuccess = createAction(
  '[Login] User login not success',
  props<{
    payload: {
      status: number;
      username: string;
      nextAllowedLoginTimeUtc: Date | undefined;
    };
  }>()
);

export const loginOfflineInventory = createAction(
  '[Login] User login offline inventory',
  props<{
    payload: {
      kitchenNumber: number;
    };
  }>()
);

export const loginOfflineInventorySuccess = createAction(
  '[Login] User login offline inventory success',
  props<{ payload: { status: number } }>() //TODO: Ändern Datentyp
);
export const loginOfflineInventoryNotSuccess = createAction(
  '[Login] User login offline inventory not success',
  props<{
    payload: {
      status: number;
    };
  }>()
);

export const xsrfToken = createAction('[XSRF]');

export const loginFailed = createAction(
  '[Login] User login failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const logout = createAction('[Logout] User logout');

export const logoutSuccess = createAction('[Logout] User logout success');
