<div class="grid">
  <div class="col-12">
    <span>{{ dialogParameters.message }}</span>
    <br />
  </div>
</div>
<div class="dialog-content-footer">
  <button
    type="button"
    pButton
    icon="fa-regular fa-check"
    (click)="closeDialog()"
    label="Ok"
  ></button>
</div>
