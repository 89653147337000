import packageJson from 'package.json';
import {
  OpenTelemetryConfiguration,
  OpenTelemetryProcessorType,
} from 'ui-core-otel';

export interface EnvironmentFeature {
  name: boolean;
  isEnabled: boolean;
}

export enum EnvironmentType {
  dev,
  devPwa,
  production,
  expiremental,
}
export interface Environment {
  type: EnvironmentType;
  VERSION: string;
  expirementalFeatures: EnvironmentFeature[];
  production: boolean;
  devPwa: boolean;
  openTelemetryConfig: OpenTelemetryConfiguration;
}

export class EnvironmentBuilder {
  private _type: EnvironmentType = EnvironmentType.dev;
  private _version = packageJson.version;
  private _expirementalFeatures: EnvironmentFeature[] = [];
  private _openTelemetryConfig: OpenTelemetryConfiguration = {
    appName: 'MMU',
    url: '/traces',
    consoleOutput: true,
    processor: OpenTelemetryProcessorType.Simple,
    traceResponseBody: true,
  };

  production(): EnvironmentBuilder {
    this._type = EnvironmentType.production;
    return this;
  }

  expiremental(): EnvironmentBuilder {
    this._type = EnvironmentType.expiremental;
    return this;
  }
  devPwa(): EnvironmentBuilder {
    this._type = EnvironmentType.devPwa;
    return this;
  }

  build(): Environment {
    let VERSION = this._version;
    // only in expiremntal environment
    let expirementalFeatures: EnvironmentFeature[] = [];
    let production = false;
    let devPwa = false;
    let openTelemetryConfig = this._openTelemetryConfig;

    const type = this._type;

    switch (type) {
      case EnvironmentType.expiremental:
        VERSION = `${this._version}_ex`;
        expirementalFeatures = this._expirementalFeatures;
        openTelemetryConfig = {
          appName: 'MMU',
          url: '/traces',
          processor: OpenTelemetryProcessorType.Batch,
          traceResponseBody: false,
        };
        break;
      case EnvironmentType.production:
        production = true;
        break;
      case EnvironmentType.devPwa:
        devPwa = true;
        break;
    }

    return {
      VERSION,
      type,
      expirementalFeatures,
      production,
      devPwa,
      openTelemetryConfig,
    };
  }
}
