import { NotificationDto } from './../../domain/models/notification-dto';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription, switchMap, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationFacade } from '../../domain/application/notification.facade';
import { AuthenticationV2Service } from '@sanalogic/ui-core/login';
@Component({
  selector: 'mmu-notification-center-container',
  template: `<mmu-notification-center-view
    [notificationList]="$any(notificationListLoad$ | async)"
  ></mmu-notification-center-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCenterContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  notificationList$!: Subscription;
  refreshList$!: Subscription;
  notificationListLoad$ = new BehaviorSubject<NotificationDto[]>([]);

  constructor(
    private notificationFacade: NotificationFacade,
    private authenticationService: AuthenticationV2Service
  ) {}

  ngOnInit(): void {
    this.notificationList$ = this.notificationFacade.notifications$
      .pipe(
        takeUntil(this.destroy$),
        filter((o) => o !== null)
      )
      .subscribe((notificationList) =>
        this.notificationListLoad$.next(notificationList)
      );
    this.startAutoRefresh();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    if (this.refreshList$ && !this.refreshList$.closed) {
      this.refreshList$.unsubscribe;
    }
  }

  private startAutoRefresh(): void {
    this.refreshList$ = timer(0, 300000)
      .pipe(switchMap(() => this.authenticationService.isLoggedIn$))
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.notificationFacade.loadNotificationList();
        }
      });
  }
}
