import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { logout } from '../actions/login.actions';
import * as IdleActions from '../actions/idle.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationV2Service } from '@sanalogic/ui-core/login';
import { IdleState } from '../reducers/idle.reducer';
import { IdleService } from '../../services';
import { LoginState } from '../reducers/login.reducer';

@Injectable()
export class IdleEffects {
  constructor(
    private idleStore: Store<IdleState>,
    private actions$: Actions,
    private idleService: IdleService,
    private authenticationService: AuthenticationV2Service,
    private loginStore: Store<LoginState>
  ) {}

  startIdle = createEffect(() =>
    this.actions$.pipe(
      ofType(IdleActions.idleStartWatching),
      mergeMap((action) => {
        this.idleService.stopWatching();
        this.idleStore.dispatch(IdleActions.idleStopped());

        const idleServiceConfig = this.idleService.getConfigValue();
        idleServiceConfig.idle = action.payload.idle;

        this.idleStore.dispatch(
          IdleActions.idleConfigure({
            payload: {
              timeout: idleServiceConfig.timeout,
              ping: idleServiceConfig.ping,
              idle: idleServiceConfig.idle,
            },
          })
        );

        this.idleService.setConfigValues(idleServiceConfig);
        this.idleStore.dispatch(IdleActions.idleConfigured());

        this.idleService
          .onTimeout()
          .pipe(withLatestFrom(this.authenticationService.isLoggedIn$))
          .subscribe(([, isLoggedIn]) => {
            if (isLoggedIn) {
              this.idleStore.dispatch(IdleActions.idleTimeoutReach());

              this.loginStore.dispatch(logout());
            }

            this.idleService.startWatching();
          });

        this.idleService.onTimerStart().subscribe();

        this.idleService.startWatching();

        return of(IdleActions.idleStarted());
      })
    )
  );
}
