import { NotificationDto } from './../../domain/models/notification-dto';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'mmu-notification-list-view',
  templateUrl: './notification-list-view.component.html',
  styleUrls: ['./notification-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListViewComponent {
  @Input() notifications: NotificationDto[] = [];
  @Input() notificationsIsPending = false;
  @Input() ovelayPanel: OverlayPanel | null = null;
  @Output() setNotificationReadEmitter = new EventEmitter<number>();

  setNotificationRead(number: number): void {
    this.setNotificationReadEmitter.emit(number);
  }

  close(): void {
    this.ovelayPanel?.hide();
  }

  trackByIndex(i: number): number {
    return i;
  }
}
